import { Helmet } from 'react-helmet';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import Footer from '@/components/Footer';
import * as styles from './view.module.css';
import { scaler } from '../../utils';
import Castform from '../../templates/Castform';
import Celebi from '../../templates/Celebi';
import DatabaseContext from '../../contexts/DatabaseContext';
import Gengar from '../../templates/Gengar';
import Glalie from '../../templates/Glalie';
import Onyx from '../../templates/Onyx';
import LoadingScreen from '../../components/router/LoadingScreen';
import Orientalent from '../../templates/Orientalent';
import fontSizeOptions from '../../data/fontSizeOptions';

const ResumeViewer = ({ sId }) => {
  const { i18n } = useTranslation();
  const [resume, setResume] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getResume, getResumeId, getCompany } = useContext(DatabaseContext);
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          companyName
        }
      }
    }
  `);

  const handleNotFound = () => {
    navigate('/');
    toast.error(
      `The resume you were looking for does not exist anymore... or maybe it never did?`,
    );
    return null;
  };

  useEffect(() => {
    (async () => {
      const id = await getResumeId(sId);
      if (!id) {
        return handleNotFound();
      }
      const data = await getResume(id);
      if (!data) {
        return handleNotFound();
      }

      if (data?.companyId) {
        const company = await getCompany(data?.companyId);
        let companyEmail = '';
        let companyPhone = '';
        if (company.replaceContact) {
          companyEmail = company ? company.email : '';
          companyPhone = company ? company.phone : '';
        }
        setResume({
          ...data,
          profile: {
            ...data.profile,
            email: companyEmail,
            phone: companyPhone,
          },
        });
      } else {
        setResume(data);
      }

      i18n.changeLanguage(data.metadata.language || 'en');

      for (const [key, sizeDefault] of Object.entries(fontSizeOptions)) {
        document.documentElement.style.setProperty(
          key,
          `${scaler(data.metadata.fontSize) * sizeDefault}rem`,
        );
      }

      return setLoading(false);
    })();
  }, [sId]);

  return useMemo(() => {
    if (loading) {
      return <LoadingScreen />;
    }

    return (
      <>
        <div className={styles.container}>
          <Helmet>
            <title>{site.siteMetadata.companyName}</title>
            <link rel="canonical" href={`https://happycv.se/r/${sId}`} />
          </Helmet>

          <div
            className={styles.page}
            style={{ backgroundColor: resume.metadata.colors.background }}
          >
            {resume.metadata.template === 'onyx' && <Onyx data={resume} />}
            {resume.metadata.template === 'gengar' && <Gengar data={resume} />}
            {resume.metadata.template === 'castform' && (
              <Castform data={resume} />
            )}
            {resume.metadata.template === 'glalie' && <Glalie data={resume} />}
            {resume.metadata.template === 'celebi' && <Celebi data={resume} />}
            {resume.metadata.template === 'orientalent' && (
              <Orientalent data={resume} />
            )}
          </div>
        </div>
        <Footer />
      </>
    );
  });
};

export default memo(ResumeViewer);
